$(document).ready(function () {
    $(document).on('click', '#add_client_address', async function () {
        $(this).prop('disabled', true);
        await $.get($('#_url_api_front_client_address_view').val())
            .done(function (data) {
                $('#client_addresses').append(data)
            })
        $(this).prop('disabled', false);
    });

    $(document).on('click', '.modal_client_orders', async function () {
        await $.get($('#_url_api_client_modal_orders').val() + '?client_id=' + $(this).data('client_id'))
            .done(function (data) {
                $('#clientOrdersBody').html(data);
                $('#clientOrders').modal('show');
            })
    });
});


