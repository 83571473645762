$(document).ready(function () {
    $(document).on('click', '#add_product_composition', async function () {
        $(this).prop('disabled', true);
        await $.get($('#_url_api_front_product_composition_view').val() + '?parent_id=' + $('#product_id').val())
            .done(function (data) {
                $('#product_compositions').append(data)
            })
        $(this).prop('disabled', false);
        $(".js-example-basic-multiple").select2();
    });
    $(document).on('change', '.search_d_input', function () {
        searchProducts();
    });
    $(document).on('keyup', '.search_d_input', function () {
        searchProducts();
    });

    let hash = '';

    async function searchProducts() {
        let value = $('.search_d_input').val();
        if (value) {
            hash = Date.now();
            $('.search_d_products').show();
            await $.get($('#_url_api_front_search_d_products').val() + '?q=' + value + '&hash=' + hash)
                .done(function (data) {
                    if (data.hash === hash.toString()) {
                        $('#search_d_products').html(data.view);
                        if (!$('.search_d_input').val()) {
                            $('.search_d_products').hide();
                        }
                    }
                })
        } else {
            $('.search_products').hide();
        }
    }
});
