$(document).ready(function () {

    $(document).on('click', '.report_print', function (e) {
        e.preventDefault();
        let form = $(this).parent().parent();
        let elems = form.find('.filters');
        let url = $(this).prop('href');
        elems.each(function (index, item) {
            if ($(item).val()) {
                url += '&' + $(item).data('filter') + '=' + $(item).val()
            }
        });
        window.open(url);
    });

    $(document).on('click', '.report_select_date', function (e) {
        console.log($(this).data('from'));
        console.log($(this).data('to'));
        $(this).parent().parent().find('input[data-filter="date_from"]').val($(this).data('from'));
        $(this).parent().parent().find('input[data-filter="date_to"]').val($(this).data('to'));
    });

    $(document).on('click', '.report_view', function (e) {
        e.preventDefault();
        let form = $(this).parent().parent();
        let elems = form.find('.filters');
        let url = $(this).data('href');
        elems.each(function (index, item) {
            if ($(item).val()) {
                url += '&' + $(item).data('filter') + '=' + $(item).val()
            }
        });
        let type = $(this).data('type');
        $.get(url)
            .done(function (data) {
                $('#view_' + type).html(data);
            })
    });
});
