$(document).ready(function () {
    $(document).on('click', '._add_to_order', async function (e) {
        let name = $(this).data('name');
        let price = $(this).data('price');
        let hide = $(this).data('hide');
        let product_id = $(this).data('id');
        let product = $(document).find('#order_product_' + product_id)
        if (product.html()) {
            product.find('._product_quantity').val(parseInt(product.find('._product_quantity').val()) + 1);
        } else {
            let html = '<tr class="order_product" id="order_product_' + product_id + '">' +
                '<td scope="row">' + name + '</td>' +
                '<td> <div class="d-flex">' +
                '<button type="button" class="button_plus_minus" data-type="minus">-</button>' +
                '<input name="order[products][' + product_id + '][quantity]"' +
                ' type="number" min="1"' +
                ' class="product_input_number _product_quantity"' +
                ' value="1"/>' +
                '<button type="button" class="button_plus_minus" data-type="plus">+</button>' +
                '</div></td>' +
                '<td><input name="order[products][' + product_id + '][price]" type="number" min="1" class="product_input_number _product_price" value="' + price + '"/></td>' +
                '<td><div style="display: flex;justify-content: space-between"><div class="_product_total">' + price + '</div><div class="delete_product">X</div></div></td>' +
                '</tr>';

            $('#order_product_footer').before(html);
        }

        if (hide) {
            $('.search_products').hide();
        }
    });
    $(document).on('click', '.order_clear', async function (e) {
        $(document).find('.order_product').remove();
    });
    $(document).on('click', '.show_hide_discounts', async function (e) {
        $('.percent_menu').toggle();
    });
    $(document).on('click', '.discount_remove_p', async function (e) {
        discount_remove_p();
    });

    $(document).on('click', '.discount_remove_a', async function (e) {
        discount_remove_a();
    });

    function discount_remove_p() {
        $('.percent_menu').hide();
        $('.order_discount_p').css('opacity', 0);
        $('.order_discount_p').html('');
        $('#order_discount_percent').val(0);
    }

    function discount_remove_a() {
        $('.percent_menu').hide();
        $('.order_discount_a').css('opacity', 0);
        $('.order_discount_a').html('');
        $('#order_discount_amount').val(0);
    }

    $(document).on('click', '.add_discount', async function (e) {
        let percent = $(this).data('percent');
        let order_discount = $('.order_discount_p');
        order_discount.html('-' + percent + '%');
        order_discount.css('opacity', 1);
        $('#order_discount_percent').val(percent);
        $('.percent_menu').hide();
    });

    $(document).on('click', '#add_discount_amount', async function (e) {
        add_discount_amount();
    });

    $(document).on('keypress', '#discount_amount', async function (e) {
        if ((e.keyCode || e.which) === 13) {
            add_discount_amount();
        }
    });

    function add_discount_amount() {
        let amount = $('#discount_amount').val();
        if (amount > 0) {
            let order_discount = $('.order_discount_a');
            order_discount.html('-' + amount + ' руб');
            order_discount.css('opacity', 1);
            $('#order_discount_amount').val(amount);
        } else {
            let order_discount = $('.order_discount_a');
            order_discount.html('');
            order_discount.css('opacity', 0);
            $('#order_discount_amount').val(0);
        }
        $('.percent_menu').hide();
    }

    setInterval(function () {
        changeProduct();
        if ($('#change_filter_billing_at').val() === 'period') {
            $('.period_filter').show();
        } else {
            $('.period_filter').hide();
        }
    }, 50);

    function changeProduct() {
        let elems = $(document).find('.order_product')
        let total_price = 0;
        if (elems.length > 0 && $('[name="client[phone]"]').val() && $('[name="client[name]"]').val()) {
            $('.save_order').prop('disabled', false);
        } else {
            $('.save_order').prop('disabled', true);
        }
        elems.each(function (index, product) {
            let price = $(product).find('._product_price').val();
            let quantity = $(product).find('._product_quantity').val();
            let total = parseInt(price) * parseInt(quantity);
            if (total > 0) {
                total_price += total;
                total += ' руб';
            } else {
                total = '-';
            }
            $(product).find('._product_total').html(total);
        });
        let order_discount_amount = $('#order_discount_amount').val();
        let order_discount_percent = $('#order_discount_percent').val();
        let total_price_discount = total_price;

        if (order_discount_percent) {
            total_price_discount = total_price - (total_price / 100 * order_discount_percent);
        }

        if (order_discount_amount) {
            total_price_discount -= order_discount_amount;
        }
        total_price_discount = Math.round(total_price_discount);
        $('.total_order_price').html(total_price + ' руб');
        $('.total_order_price_discount').html(total_price_discount + ' руб');

        if (order_discount_amount > 0) {
            $('.order_discount_a').css('opacity', 1);
        }
        if (order_discount_percent > 0) {
            $('.order_discount_p').css('opacity', 1);
        }

        if ($('#order_pickup').prop('checked')) {
            $('.hide_order_address').hide();
        } else {
            $('.hide_order_address').show();
        }
    }

    let last_change = null;
    $(document).on('change', '.change_fields', async function () {
        let change_timeout = !!$(this).data('change_timeout');
        let not_reload = !!$(this).data('not_reload');
        let order_id = $(this).data('order_id');
        let field = $(this).data('field');
        let value = $(this).val();
        if (field === 'is_courier_report') {
            value = $(this).prop('checked') ? 'on' : 'off';
        }
        if (change_timeout && !not_reload) {
            last_change = Date.now();
            setTimeout(function () {
                if (Date.now() - last_change >= 900) {
                    $.get($('#_url_api_front_order_view').val() + '?order_id=' + order_id + '&' + field + '=' + value)
                        .done(function (data) {
                            $('#order_' + order_id).html(data.view);
                            if (data.order_ids && data.order_ids.length) {
                                data.order_ids.map(function (id) {
                                    $.get($('#_url_api_front_order_view').val() + '?order_id=' + id)
                                        .done(function (d) {
                                            $('#order_' + id).html(d.view);
                                        })
                                })
                            }
                        })
                }
            }, 1000)
        } else {
            $.get($('#_url_api_front_order_view').val() + '?order_id=' + order_id + '&' + field + '=' + value)
                .done(function (data) {
                    if (!not_reload) {
                        $('#order_' + order_id).html(data.view);
                    }
                    if (data.order_ids && data.order_ids.length) {
                        data.order_ids.map(function (id) {
                            $.get($('#_url_api_front_order_view').val() + '?order_id=' + id)
                                .done(function (d) {
                                    $('#order_' + id).html(d.view);
                                })
                        })
                    }
                })
        }
    });
    $(document).on('change', '.order_filters', async function () {
        changeFilter();
    });
    $(document).on('change', '#is_delete', async function () {
        changeFilter();
    });

    function changeFilter() {
        let name = 'order_list';
        $('#' + name).show();
        $.get($('#_api_' + name).val() + getQueryFilter())
            .done(function (data) {
                $('#' + name).html(data)
            })
    }

    $(document).on('click', '.print_button', function () {
        $(this).find('.print_menu').toggle();
    });

    $(document).on('click', '.delete_product', function (e) {
        $(this).parent().parent().parent().remove();
    })
    $(document).on('change', '.change_filter_billing_at', function (e) {
        let value = $(this).val();
        let from = 'created_' + value + '_from';
        let to = 'created_' + value + '_to';
        $('#change_filter_billing_at').val(value);
        if (value !== 'period') {
            $('#created_from').val($('#' + from).val());
            $('#created_to').val($('#' + to).val());
            changeFilter();
        }
    });

    function searchOrdersFocus() {
        let elem = $('.search_orders');
        let value = elem.val();
        elem.val('').val(value);
        elem.focus();
    }

    $(document).on('change', '.search_orders', async function () {
        last_change = Date.now();
        setTimeout(async function () {
            if (Date.now() - last_change >= 1000) {
                await changeFilter();
                searchOrdersFocus();
            }
        }, 1100)
    });

    $(document).on('keyup', '.search_orders', async function () {
        last_change = Date.now();
        setTimeout(async function () {
            if (Date.now() - last_change >= 1000) {
                await changeFilter();
                searchOrdersFocus();
            }
        }, 1100)
    });

    function getQueryFilter() {
        hash = Date.now();
        let elems = $('.order_filters');
        let query = '';
        elems.each(function (index, filter) {
            let value = $(filter).val();
            if (value) {
                query = query + (query ? '&' : '?');
                query = query + $(filter).data('name') + '=' + value;
            }
        });
        let search = $('.search_orders').val();
        if (search) {
            query = query ? (query + '&q=' + search) : query + '?q=' + search;
        }
        if ($('#is_delete').prop('checked')) {
            query = query ? (query + '&is_delete=da') : (query + '?is_delete=da');
        }
        return query ? (query + '&hash=' + hash) : (query + '?hash=' + hash);
    }

    let hash = '';

    async function searchOrders() {
        let value = $('.search_orders').val();
        if (value) {
            hash = Date.now();
            let query = getQueryFilter();
            await $.get($('#_url_api_front_search_orders').val() + query + '&q=' + value + '&hash=' + hash)
                .done(function (data) {
                    if (data.hash === hash.toString()) {
                        $('.order_item').hide();
                        let ids = data.ids;
                        ids.map(function (id) {
                            $('#order_' + id).show();
                        })
                        if (!$('.search_orders').val()) {
                            $('.order_item').show();
                        }
                    }
                })
        } else {
            $('.order_item').show();
        }
    }

    if (location.pathname == '/') {
        changeFilter();
    }

    // $(document).on('change', '.search_input', function () {
    //     searchProducts();
    // });
    $(document).on('keyup', '.search_input', function () {
        searchProducts();
    });

    let hash2 = '';

    async function searchProducts() {
        let value = $('.search_input').val();
        if (value) {
            hash2 = Date.now();
            $('.search_products').show();
            $('#search_products').html('');
            await $.get($('#_url_api_front_search_products').val() + '?q=' + value + '&hash=' + hash2)
                .done(function (data) {
                    if (data.hash === hash2.toString()) {
                        $('#search_products').html(data.view);
                        if (!$('.search_input').val()) {
                            $('.search_products').hide();
                        }
                    }
                })
        } else {
            $('.search_products').hide();
        }
    }

    $(document).on('click', '.status_changes_view', function () {
        $(this).parent().find('.status_changes').slideToggle(200);
    });

    $(document).on('click', '.button_plus_minus', function () {
        let type = $(this).data('type');
        let input = $(this).parent().find('input');
        let value = parseInt(input.val());

        if (type === 'plus') {
            value++;
        } else {
            value--;
        }
        if (value <= 0) {
            value = 1;
        }
        input.val(value);
    });
});
