require("./bootstrap");
require("./client.js");
require("./product.js");
require("./order.js");
require("./report.js");
require("./imask.js");
require("./masks.js");
require("./select2.min.js");
require("./selects.js");

var moment = require("moment");

$(document).ready(function () {
    $(document).on("click", ".js-confirm", async function (e) {
        e.preventDefault();
        let message = $(this).data("message");
        let result = confirm(message ? message : "Удалить?");
        let success_view = $(this).data("success_view");
        if (result) {
            await $.get($(this).prop("href"));
            if (success_view === "reload") {
                location.reload();
            } else if (success_view === "delete_order") {
                $("#order_" + $(this).data("order_id")).remove();
            } else {
                await openBlock(null, success_view);
            }
        }
    });
    $(document).on("click", ".js-export", async function (e) {
        e.preventDefault();
        let result = confirm("Экспортировать?");
        if (result) {
            const modal = document.getElementById("export-result");
            const modalLoader = modal.querySelector(
                '[data-export-result="loader"]'
            );
            const modalSuccess = modal.querySelector(
                '[data-export-result="success"]'
            );
            const modalError = modal.querySelector(
                '[data-export-result="error"]'
            );
            const modalClose = modal.querySelectorAll(
                '[data-export-result="close"]'
            );
            const modalErrorCode = modal.querySelector(
                '[data-export-result="error-code"]'
            );
            const modalErrorDescription = modal.querySelector(
                '[data-export-result="error-description"]'
            );
            const parseResults = (json) => {
                console.log(json);
                if (json.httpcode === 200) {
                    modalLoader.style.display = "none";
                    modalSuccess.style.display = "";
                    modalSuccess.classList.add("fade");
                    modalSuccess.classList.add("show");
                } else {
                    modalErrorDescription.innerHTML =
                        json.response.errorDescription;
                    modalErrorCode.innerHTML += json.httpcode;
                    modalLoader.style.display = "none";
                    modalError.style.display = "";
                    modalError.classList.add("fade");
                    modalError.classList.add("show");
                }
            };

            modalClose.forEach((modalCloseButton) => {
                $(modalCloseButton).on("click", (b) => {
                    b.preventDefault();
                    location.reload();
                });
            });

            modal.style.display = "";
            modal.classList.add("show");

            fetch($(this).prop("href"), {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                },
            })
                .then((response) => response.json())
                .then((json) => parseResults(json));
        }
    });

    $(document).on("click", ".js-export-check", async function (e) {
        e.preventDefault();

        const modal = document.getElementById("export-status");
        const modalLoader = modal.querySelector(
            '[data-export-result="loader"]'
        );
        const modalSuccess = modal.querySelector(
            '[data-export-result="success"]'
        );
        const modalError = modal.querySelector('[data-export-result="error"]');
        const modalClose = modal.querySelectorAll(
            '[data-export-result="close"]'
        );
        const modalErrorCode = modal.querySelector(
            '[data-export-result="error-code"]'
        );
        const modalErrorDescription = modal.querySelector(
            '[data-export-result="error-description"]'
        );
        const parseResults = (json) => {
            console.log(json);
            if (json.httpcode === 200) {
                modalLoader.style.display = "none";
                modalSuccess.style.display = "";
                modalSuccess.classList.add("fade");
                modalSuccess.classList.add("show");
            } else {
                modalErrorDescription.innerHTML =
                    json.response.errorDescription ?? modalErrorDescription.innerHTML;
                modalErrorCode.innerHTML += json.httpcode;
                modalLoader.style.display = "none";
                modalError.style.display = "";
                modalError.classList.add("fade");
                modalError.classList.add("show");
            }
        };

        modalClose.forEach((modalCloseButton) => {
            $(modalCloseButton).on("click", (b) => {
                b.preventDefault();
                location.reload();
            });
        });

        modal.style.display = "";
        modal.classList.add("show");

        fetch($(this).prop("href"), {
            method: "GET",
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((json) => parseResults(json));
    });
    $(document).on("click", ".js-sync", async function (e) {
        e.preventDefault();
        const modal = document.getElementById("export-result");
        const modalLoader = modal.querySelector(
            '[data-export-result="loader"]'
        );
        const modalLoaderText = modalLoader.querySelector("h3");
        modalLoaderText.innerHTML = "Синхронизация";
        const modalSuccess = modal.querySelector(
            '[data-export-result="success"]'
        );
        const modalError = modal.querySelector('[data-export-result="error"]');
        const modalClose = modal.querySelectorAll(
            '[data-export-result="close"]'
        );
        const modalErrorCode = modal.querySelector(
            '[data-export-result="error-code"]'
        );
        const modalErrorDescription = modal.querySelector(
            '[data-export-result="error-description"]'
        );
        const reactTo = (response) => {
            if (response === 200) {
                modal.style.display = "none";
            } else {
                modalErrorDescription.innerHTML =
                    "Произошла ошибка, страница не может быть загружена";
                modalErrorCode.innerHTML += "";
                modalLoader.style.display = "none";
                modalError.style.display = "";
                modalError.classList.add("fade");
                modalError.classList.add("show");
            }
        };

        modalClose.forEach((modalCloseButton) => {
            $(modalCloseButton).on("click", (b) => {
                b.preventDefault();
                location.reload();
            });
        });

        modal.style.display = "";
        modal.classList.add("show");
        const myPromise = new Promise((resolve) => {
            resolve(openNewBlock(this));
        });
        myPromise.then((response) => {
            reactTo(response);
        });

        // fetch($(this).data("href"), {
        //     method: "GET",
        //     headers: {
        //         "Content-type": "application/json; charset=UTF-8",
        //     },
        // }).then((response) => reactTo(response));
    });

    $(document).on("click", "._open_block", async function (e) {
        e.preventDefault();
        openBlock(this);
    });

    async function openBlock(el = null, name = null) {
        name = name ? name : $(el).data("block");

        $("._block").hide();
        if (name === "order_new") {
            $("#order_edit").html(" ");
        } else if (name === "order_edit") {
            $("#order_new").html(" ");
        }

        $(".total_order_price").html(0 + " руб");
        $(".total_order_price_discount").html(0 + " руб");

        $("#" + name).show();
        let query = "";
        if (el && $(el).data("order_id")) {
            query = "?order_id=" + $(el).data("order_id");
        }
        if (name === "order_list") {
            query = getQueryFilter();
        }
        let url = $("#_api_" + name).val();
        if (el && $(el).data("href")) {
            url = $(el).data("href");
        }
        await $.get(url + query).done(function (data) {
            $("#" + name).html(data);
        });
        $(".datepicker-here").datetimepicker({
            step: 30,
            inverseButton: false,
            lang: "ru",
            dayOfWeekStart: 1,
            format: "d.m.Y H:i",
            formatDate: "d.m.Y",
        });
        //     .each(function () {
        //     if ($(this).val())
        //         $(this)
        //             .data('datepicker')
        //             .selectDate(moment($(this).val(), 'DD/MM/YYYY').toDate())
        // })
    }

    async function openNewBlock(el = null, name = null) {
        name = name ? name : $(el).data("block");

        $("._block").hide();
        if (name === "order_new") {
            $("#order_edit").html(" ");
        } else if (name === "order_edit") {
            $("#order_new").html(" ");
        }

        $(".total_order_price").html(0 + " руб");
        $(".total_order_price_discount").html(0 + " руб");

        $("#" + name).show();
        let query = "";
        if (el && $(el).data("order_id")) {
            query = "?order_id=" + $(el).data("order_id");
        }
        if (name === "order_list") {
            query = getQueryFilter();
        }
        let url = $("#_api_" + name).val();
        if (el && $(el).data("href")) {
            url = $(el).data("href");
        }
        await $.get(url + query).done(function (data) {
            $("#" + name).html(data);
        });
        $(".datepicker-here").datetimepicker({
            step: 30,
            inverseButton: false,
            lang: "ru",
            dayOfWeekStart: 1,
            format: "d.m.Y H:i",
            formatDate: "d.m.Y",
        });
        //     .each(function () {
        //     if ($(this).val())
        //         $(this)
        //             .data('datepicker')
        //             .selectDate(moment($(this).val(), 'DD/MM/YYYY').toDate())
        // })
        return 200;
    }

    function getQueryFilter() {
        let elems = $(".order_filters");
        let query = "";
        elems.each(function (index, filter) {
            let value = $(filter).val();
            if (value) {
                query = query + (query ? "&" : "?");
                query = query + $(filter).data("name") + "=" + value;
            }
        });
        return query;
    }

    $(document).on("click", ".save_form", async function (e) {
        e.preventDefault();
        let id = $(this).data("form_id");
        let success_view = $(this).data("success_view");
        if ($.validator && $.validator.messages) {
            $.validator.messages.required = "Обязательно для заполнения";
        }
        if ($("#" + id).valid()) {
            let formData = $("#" + id).serialize();
            await $.post($("#" + id).data("url"), formData)
                .done(async function (data) {
                    await openBlock(null, success_view);
                })
                .fail(function (data) {});
            let list_order_id = $(this).data("list_order_id");

            if (list_order_id) {
                let id = "#order_" + list_order_id;
                $("html").animate({ scrollTop: $(id).offset().top - 50 });
            }
        }
    });

    $(document).on("click", ".set_tab", function () {
        $(".tab-pane").removeClass("active");
        $(".tab-pane").removeClass("show");

        let id = $(this).data("id");

        $("#" + id).addClass("active");
        $("#" + id).addClass("show");
    });

    $(document).on("click", ".js-delete-table-item", function () {
        let parent = $(this).parent().parent();
        parent.find("." + $(this).data("input")).val("yes");
        parent.hide();
    });

    if ($.validator && $.validator.messages) {
        $.validator.messages.required = "Обязательно для заполнения";
    }
});
